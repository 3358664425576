import React, { useCallback, useState } from 'react';
import { Input, Badge } from 'reactstrap';

const EMAIL_REGEX = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;
const validateEmail = (email) => EMAIL_REGEX.test(email);

const EmailSelector = ({ recipients, onChange, onInputChange, existingRecipients, setInvalidEmail }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedRecipients, setSelectedRecipients] = useState(existingRecipients);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleInputChange = useCallback((e) => {
    const value = e.target.value;
    setInputValue(value);
    onInputChange?.(value);

    if (value) {
      const suggestions = recipients.filter(
        (recipient) =>
          recipient.name.toLowerCase().includes(value.toLowerCase()) ||
          recipient.email.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(suggestions);
      if (validateEmail(value)) {
        setInvalidEmail?.(false);
      }
    } else {
      setFilteredSuggestions([]);
      setInvalidEmail?.(false);
    }
  }, [recipients]);

  const handleSelectSuggestion = useCallback((email) => () => {
    if (!selectedRecipients.includes(email)) {
      const updatedRecipients = [...selectedRecipients, email];
      setSelectedRecipients(updatedRecipients);
      onChange(updatedRecipients.join(","));
    }
    setInputValue('');
    onInputChange?.('');
    setInvalidEmail?.(false);
    setFilteredSuggestions([]);
  }, [selectedRecipients, onChange]);

  const handleAddManualRecipient = useCallback((e) => {
    if (e.key === 'Enter' || e.key === ',') {
      const email = inputValue.trim();
      const isValidEmail = validateEmail(email);

      setInvalidEmail?.(!isValidEmail);
      if (!isValidEmail) return;

      if (!selectedRecipients.includes(email)) {
        const updatedRecipients = [...selectedRecipients, email];
        setSelectedRecipients(updatedRecipients);
        onChange(updatedRecipients.join(","));
      }
      setInputValue('');
      onInputChange?.('');
      setFilteredSuggestions([]);
      e.preventDefault();
    }
  }, [selectedRecipients, onChange, inputValue]);

  const handleRemoveRecipient = useCallback((email) => () => {
    const updatedRecipients = selectedRecipients.filter(
      (recipient) => recipient !== email
    );
    setSelectedRecipients(updatedRecipients);
    onChange(updatedRecipients.join(","));
  }, [selectedRecipients, onChange]);

  return (
    <div>
      <div className="selected-recipients mb-2">
        {selectedRecipients.map((email) => (
          <Badge
            key={email}
            color="secondary"
            onClick={handleRemoveRecipient(email)}
            style={{ cursor: 'pointer' }}
          >
            <span style={{ fontSize: "13px" }}>{email} &times;</span>
          </Badge>
        ))}
      </div>
      <Input
        className="form-control"
        placeholder="Add recipients"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleAddManualRecipient}
      />
      {filteredSuggestions.length > 0 && (
        <div className="suggestions-list mt-1">
          {filteredSuggestions.map((recipient) => (
            <div
              key={recipient.email}
              className="suggestion-item"
              onClick={handleSelectSuggestion(recipient.email)}
              style={{ cursor: 'pointer', padding: '5px 10px', borderBottom: '1px solid #ddd' }}
            >
              {recipient.name} ({recipient.email})
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EmailSelector;
