import Axios from 'axios';
import moment from "moment";

const clickstream_url = 'https://81z7p60ky4.execute-api.us-east-1.amazonaws.com/prod/stream';

const ClickstreamService = {

    push: async function(uid, sid, eventId, eventData, eventTarget, email, oid, orgName) {
        var payload = {
            "Data": {
                "timestamp": moment().toISOString(),
                "user_id": uid,
                "device": window.navigator.userAgent,
                "app_id": "TRiA 2",
                "event_id": eventId,
                "event_target": eventTarget,
                "event_data_json": JSON.stringify(eventData),
                "session_id": sid,
                "user_email": email,
                "org_id": oid,
                "org_name": orgName,
            },
            "PartitionKey": 1
        }
        //fire and forget
        Axios.put(clickstream_url, payload);
    }
};

export default ClickstreamService;