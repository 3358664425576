import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";
import { PROVIDERS } from "./constants";

const PROVIDER_CONFIG = {
  [PROVIDERS.CONNECTRIA]: {
    getTicketPriority: ConnectriaTicketsService.ticketPriority,
    ticketStatusMap: {
      new: { color: "primary", label: "New" },
      open: { color: "success", label: "Open" },
      waitoncust: { color: "danger", label: "Waiting on Customer" },
      awaiting_customer: { color: "danger", label: "Awaiting Customer" },
      scheduled: { color: "secondary", label: "Scheduled" },
      resolved: { color: "warning", label: "Resolved" },
      closed: { color: "secondary", label: "Closed" },
      cancelled: { color: "secondary", label: "Cancelled" },
      suspended: { color: "secondary", label: "Suspended" },
    },
    defaultStatus: { color: "secondary", label: "Unknown Status" },
  },
  [PROVIDERS.LIGHTEDGE]: {
    getTicketPriority: (priority) => ({
      name: priority,
      level: priority.toLowerCase(),
    }),
    ticketStatusMap: {
      new: { color: "primary", label: "New" },
      in_progress: { color: "success", label: "In Progress" },
      awaiting_client: { color: "danger", label: "Awaiting Client" },
      closed: { color: "secondary", label: "Closed" },
      resolved: { color: "warning", label: "Resolved" },
      cancelled: { color: "secondary", label: "Cancelled" },
      suspended: { color: "secondary", label: "Suspended" },
    },
    defaultStatus: { color: "secondary", label: "Unknown Status" },
  },
};

export function getProviderConfig(provider) {
  return PROVIDER_CONFIG[provider] || PROVIDER_CONFIG[PROVIDERS.CONNECTRIA];
}
