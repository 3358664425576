import React from "react";
import globalHook from "../../UseGlobalHook";
import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";

const initialState = {
    commentText: '',
    tickets: [],
    ticketDetail: {},
    waitOnCustCount: 0,
    isLoading: true,
    filterMine: false,
    statusId: 0
};

const stateActions = {
    clearTickets: (store) => {
        store.setState( { tickets: [] });
    },
    addTicket: (store, ticket) => {
        var existingTicket = store.state.tickets.find( ({id}) => id === ticket.id );
        if (existingTicket)
            return;
        store.setState({ tickets: [...store.state.tickets, ticket] });
    },
    setTickets: (store, tickets) => {
        store.setState({ tickets });
    },
    unshiftTicket: (store, ticket) => {
        var existingTicket = store.state.tickets.find( ({id}) => id === ticket.id );
        if (existingTicket)
            return;
        store.setState({ tickets: [ticket, ...store.state.tickets] });
    },
    removeTicket: (store, ticketId) => {
        var ticketIndex = store.state.tickets.findIndex( ({id}) => id === ticketId );
        if (ticketIndex < 0)
            return;
        store.state.tickets.splice(ticketIndex,1);
    },
    setCommentText: (store, text) => {
        store.state.commentText = text;
    },
    setTicketStatus: (store, ticketId, newStatus) => {
        var ticketIndex = store.state.tickets.findIndex( ({id}) => id === ticketId );
        if (ticketIndex < 0)
            return;
        store.state.tickets[ticketIndex].status = newStatus;
    },
    replaceTickets: (store, tickets) => {
        store.setState({ 
            tickets: [ ...store.state.tickets, tickets ] 
        });
    },
    setIsLoading: (store, loadingState) => {
        store.setState({ isLoading: loadingState });
    },
    setWaitOnCustCount: (store, newCount) => {
        store.setState({ waitOnCustCount: newCount });
    },
    setTicketDetail: (store, newDetail) => {
        store.setState({ ticketDetail: newDetail });
    },
    setFilterMine: (store, newFlag) => {
        store.setState({ filterMine: newFlag });
    },
    setStatusId: (store, newId) => {
        store.setState({ statusId: newId });
    },
    setSearch: (store, newSearch) => {
        store.setState({ search: newSearch});
    }
}

export const useTriaTicketsState = globalHook(React, initialState, stateActions);
//const [useTriaTicketsState, getTriaTicketsState] = createState(initialState, stateActions);

export default useTriaTicketsState;

//utility function
function createdBy(user) {

    if(!user) return '';

    if ((user.fname == null) && (user.surname == null)) {
        return user.login;
    }
    return `${user.fname} ${user.surname}`;
}

export {
    createdBy
}

