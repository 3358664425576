import { functionsIn, has } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { appState } from "../AppState";
import { routes } from "../routes";
const checkPermissions = (userPermissions, allowedPermissions) => {
  if (allowedPermissions.length === 0) {
    return true;
  }

  return userPermissions.some((permission) =>
    allowedPermissions.includes(permission)
  );
};

const PolicyRouteGuard = ({
  user,
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
  accessCheck,
  extraAccessData,
  route,
}) => {
  try{
  let permitted;
  const [stateApp, stateAppActions] = appState();
  
  if (stateApp.superAdmin) {
    return children;
  }

  const flatRoutes = flatten(routes, "routes");
  var reg1 = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  // var reg2 = /^[0-9]*$/;
  var reg2 = /^([A-Z]{2,}\d{6,}|[0-9]*)$/; // ServiceNow IDs are a few capital letters followed by zero padded numbers
  let parameterizedUrl = false;
  let routeParts = route.split('/');
  
  let endPart = routeParts[routeParts.length - 1];
  let newPath = '';
  
  if (reg1.test(endPart) || reg2.test(endPart)) {
    //since this is a parameterized url lets set a bool to use for our replace logic
    parameterizedUrl = true;
    //newPath = r.path.split(':')[0] + endPart

  }
 
 // const routeUrls = flatRoutes.map((r) => "".concat(r.layout, (r.path.indexOf(':') >-1   ?  (r.path.split(':')[0] + endPart) : r.path)));
  
  for(var i = 0; i < flatRoutes.length; i++){
    if((flatRoutes[i].path || '').indexOf(':') >-1 && parameterizedUrl){
      flatRoutes[i].newPath = flatRoutes[i].path.split(':')[0] + endPart
    }
    else{
      flatRoutes[i].newPath = flatRoutes[i].path
    }
  } 

  const routeUrls = flatRoutes.map((r) => "".concat(r.layout, r.newPath));
  var routePolicies = [];
try{
  // if (routeUrls.indexOf(route) == -1) { // begin debugging
  //   console.log('-------------------- route', route);
  //   console.log('-------------------- routeUrls', routeUrls);
  //   console.log('-------------------- flatRoutes', flatRoutes);
  // } // end debugging
  const flatRoute = flatRoutes[routeUrls.indexOf(route)] || {};
  if (flatRoute.hasOwnProperty('policy')) {
    routePolicies = flatRoute.policy || [];
  }
  // routePolicies = flatRoutes[routeUrls.indexOf(route)].policy || [];
}
catch(e){console.error(e)}

  // console.log('Here is the route')
  // console.log(route)
  // console.log('Here is the routes')
  // console.log(routes)
  // console.log('Here is the flat routes')
 
  // console.log('Here is the route url')
  // console.log(routeUrls)
  // console.log(routeUrls.indexOf(route))

  //return children;

  // when an accessCheck function is provided, ensure that passes as well as the permissions
  if (accessCheck) {
    permitted =
      accessCheck(extraAccessData, user) &&
      checkPolicies(userPermissions, allowedPermissions);
  } else {
    // otherwise only check permissions
    permitted = checkPolicies(routePolicies);
  }

  if (stateApp.superAdmin || permitted) {
    return children;
  }
  //if everything fails direct to access denied
  window.location.href = "/access-denied";
  //
  function checkPolicies(routePolicies) {
    let user_roles = stateApp.roles_v2;
    if (routePolicies.length == 0) return true;
    if (stateApp.superAdmin) return true;
    if (user_roles.filter((e) => e.RoleName == "OrgAdmin").length > 0) return true;

    let flat_policies = flatten(user_roles, "Policies");
    let policy_codes = flat_policies.map((e) => e.code);
    for (var i = 0; i < routePolicies.length; i++) {
      if (!policy_codes.includes(routePolicies[i])) {
  
        return false;
      }
    }
    return true;
  }
}catch(ex){
  console.log(ex)
  //window.location.href = "/access-denied";
}
};

// EXAMPLE:
// <AccessControl
// extraAccessData={{ allowedNationality: "USA" }}
// accessCheck={(extraAccessData, user) =>
//   user && user.nationality === extraAccessData.allowedNationality
// }
// renderNoAccess={() => (
//   <View style={styles.countryWarning}>
//     <Text style={styles.noAccessText}>
//       Only Nuclear engineers from the USA 🇺🇸 can access this system.
//     </Text>
//   </View>
// )}
// >
// <AccessControl
// allowedPermissions={["read:stats"]}
// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
// >
// <StatsPanel />
// </AccessControl>
function flatten(ary, prop) {
  var ret = [];
  for (var i = 0; i < ary.length; i++) {
    if (Array.isArray(ary[i][prop])) {
      ret = ret.concat(flatten(ary[i][prop], prop));
    } else {
      ret.push(ary[i]);
    }
  }
  return ret;
}
export default PolicyRouteGuard;
