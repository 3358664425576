import { Auth } from 'aws-amplify';
import axios from 'axios';
import UserService from '../services/UserService';
import { ACCOUNTS_API, DOMAIN, AWS_IDENTITY_POOL_ID, AWS_USER_POOL_ID, AWS_POOL_CLIENT_ID, LIGHTEDGE_DOMAIN } from '../config';
import jwt_decode from 'jwt-decode';
const ACCESS_TOKEN = 'accessToken',
  ID_TOKEN = 'idToken',
  ORGANIZATION_COOKIE_NAME = 'OrganizationId',
  DIVVY_COOKIE = 'session_id',
  CUSTOMER_COOKIE_NAME = 'CustomerId';

Auth.configure({
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: AWS_IDENTITY_POOL_ID,
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: AWS_POOL_CLIENT_ID,
  cookieStorage: {
    domain: '.' + LIGHTEDGE_DOMAIN ,
    secure: false,
  },
});

const Cognito = {
  async getSession() {
    try {
      const session = await Auth.currentSession();
      this.setTokens(session);
      return { success: true, session: session };
    } catch (err) {
      return { success: false, error: err };
    }
  },
  async hasMfaEnabled() {
    try {
      
      const user = await Auth.currentAuthenticatedUser();

      const result = await Auth.getPreferredMFA(user);

    //  console.log('MFA Enabled ' + ((result || '') == 'SMS_MFA' || (result || '') == 'SOFTWARE_TOKEN_MFA'));
      return (result || '') == 'SMS_MFA' || (result || '') == 'SOFTWARE_TOKEN_MFA';
    } catch (err) {
      console.log('Error MFA get preferred: ', err);
      return false;
    }
  },
  async getUserInfo() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return { success: true, user: user };
    } catch (err) {
      return { success: false, error: err };
    }
  },

  setTokens(session) {
    localStorage.setItem(ACCESS_TOKEN, session.getAccessToken().getJwtToken());
    localStorage.setItem(ID_TOKEN, session.getIdToken().getJwtToken());
  },

  async getAccessToken() {
    const session = await this.getSession();
    if (session.success) {
      return session.session.getAccessToken().getJwtToken();
    }
    return null;
  },
  async getAccessTokenDecoded() {
    const session = await this.getSession();
    if (session.success) {
      var decoded = jwt_decode(session.session.getAccessToken().getJwtToken());
//      console.log(decoded);
      return decoded;
    }
    return null;
  },
  async getIdToken() {
    const session = await this.getSession();
    if (session.success) {
      return session.session.getIdToken().getJwtToken();
    }
    return null;
  },

  async signOut() {
    try {
      await Auth.signOut({ global: true });
      return { success: true };
    } catch (error) {
      console.error('Error sign out: ', error);
      return { success: false };
    }
  },

  async logout() {
    await this.signOut();

    this.deleteDivvyCookies();
    this.deleteCookie('TriaAccountsIdToken');
    this.deleteCookie('TriaAccountsAccessToken');

    this.deleteCookie(ORGANIZATION_COOKIE_NAME);
  },

  deleteDivvyCookies() {
    this.deleteCookie(DIVVY_COOKIE);
    this.deleteCookie('session_timeout_time');
  },

  async checkAuth(organization, superAdmin) {
    const token = await this.getAccessToken();
    if (!token) return null;

    console.log('Getting Divvy token from Cognito token', token, organization);
    try {
      const resp = await UserService.cognitoDivvyAuth(token, superAdmin ? null : organization.customer_id);
      return resp.data.session_id;
    } catch (err) {
      console.error('Cognito auth respond error', err);
    }
    return null;
  },

  async getOrganizations(user, superAdmin) {
    let organizations = [];

    if (superAdmin) {
      // get all organizations
      const orgsResp = await this.getAllOrganizations();
      if (orgsResp.success) {
        organizations = orgsResp.organizations || [];
      }
    } else {
      // get user organizations
      const userDetailResp = await this.getUserDetail();
      // console.log('---------------- userDetailResp', JSON.stringify(userDetailResp));
      if (userDetailResp.success) {
        organizations = userDetailResp.detail.orgs || [];
        for (let org of organizations) {
          org.role = org.roles.length > 0 ? org.roles[0] : null;
          if (org.roles.indexOf('OrgAdmin') > -1) {
            user.orgAdmin = true;
          }
        }
      }
    }

    return organizations;
  },

  async getAllOrganizations() {
    try {
      const resp = await axios.get(ACCOUNTS_API + '/auth/orgs', this.getOptions());
      return { success: true, organizations: resp.data.filter(org => org) };
    } catch (err) {
      console.log('Error get organizations list: ', err);
      return { success: false, organizations: [], error: err };
    }
  },

  async getOrganizationById(id) {
    try {
      const resp = await axios.get(ACCOUNTS_API + '/auth/orgs/' + id, this.getOptions());
      console.log(resp.data);
      return {
        success: true,
        clouds: [].concat(resp.data.cloud_accounts.items),
        external_id: resp?.data?.external_id,
        // cti_enabled: resp?.data?.cti_enabled,
        cti_enabled: (resp?.data?.cti_enabled == true || resp?.data?.cti_enabled == 1 || resp?.data?.cti_enabled == '1' || String(resp?.data?.cti_enabled).toLowerCase() == 'true') ? true : false,
      };
    } catch (err) {
      console.log('Error get organizations list: ', err);
      return { success: false, organization: [], error: err };
    }
  },

  getCurrentOrganization(organizations) {
    const orgId = this.getOrganizationCookie(),
      customerId = this.getCustomerCookie();
    let matched = false;
    if (orgId) {
      for (let org of organizations) {
        if (org.id === orgId) {
          if (!customerId) {
            // set customer cookie
            this.setOrganizationCookie(org);
          }
          matched = true;
          return org;
        }
      }
    }

    if (!matched && organizations.length > 0) {
      // if we don't have organization cookie choose the first organization
      let current_org = organizations[0];

      try {
        let nja = organizations.filter((e) => e.customer_id == 0);
        if (nja.length > 0) current_org = nja[0];
      } catch {
        console.log('error setting current org.');
      }

      this.setOrganizationCookie(current_org);
      return current_org;
    }

    return null;
  },

  async getUserDetail() {
    try {
      const resp = await axios.get(ACCOUNTS_API + '/auth/user_detail', this.getOptions());
      return { success: resp.data.id !== null, detail: resp.data };
    } catch (err) {
      console.log('Error get user detail: ', err);
      return { success: false, detail: null, error: err };
    }
  },

  async getResourceProperties(organization) {
    try {
      const url = ACCOUNTS_API + '/auth/orgs/' + (organization.id || 'NULL') + '/resource_properties';
      const resp = await axios.get(url, this.getOptions());
      return { success: true, resourceProperties: resp.data };
    } catch (err) {
      console.log('Error get resource properties: ', err);
      return { success: false, resourceProperties: null, error: err };
    }
  },

  getCustomerCookie() {
    return this.getCookie(CUSTOMER_COOKIE_NAME);
  },

  getOrganizationCookie() {
    return this.getCookie(ORGANIZATION_COOKIE_NAME);
  },

  setOrganizationCookie(org) {
    this.setCookie(ORGANIZATION_COOKIE_NAME, org.id);

    if (org.customer_id) {
      this.setCookie(CUSTOMER_COOKIE_NAME, `${org.customer_id}`);
    }
  },

  getDivvyCookie() {
    return this.getCookie(DIVVY_COOKIE);
  },

  setDivvyCookie(token) {
    this.setCookie(DIVVY_COOKIE, token, 1);
  },

  deleteCookie(name) {
    this.setCookie(name, '');
  },

  getCookie(name) {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || null;
  },

  setCookie(name, value, days = 364) {
    if (value === undefined) value = '';

    let time = 0;
    if (value.length > 0) {
      time = days * 24 * 3600;
    }

    document.cookie = name + '=' + value + ';domain=.mylightedge.com'  + ';SameSite=None;Secure=1;max-age=' + time + ';path=/';
  },

  getOptions() {
    const accessToken = localStorage.getItem(ID_TOKEN);

    const headers = {};
    if (accessToken) {
      headers['Authorization'] = accessToken;
    }
    return {
      headers: headers,
    };
  },
};

export default Cognito;
